.alert {
  animation: fadeIn var(--duration-in) linear forwards;
}

.alert_animation {
  animation: fadeOut var(--duration-out) linear forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
  /* 100% {
    opacity: 0;
  }  */
}
