.header_padding {
  --spacing: theme(spacing.2);
  padding: var(--spacing);
  padding-top: max(
    calc(constant(safe-area-inset-top) + var(--spacing)),
    var(--spacing)
  );
  padding-top: max(
    calc(env(safe-area-inset-top) + var(--spacing)),
    var(--spacing)
  );
}

.top_safe {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
